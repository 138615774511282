import React from "react";
import {Link} from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const SecondPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <h1>Fragn und Antworten</h1>

    <section>
      <h3>Eine Frage</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam laborum officiis possimus reiciendis
        voluptatibus. Dolor, libero, odio. Accusantium ex hic nostrum voluptatem voluptatum. Adipisci debitis nam
        perspiciatis quo reprehenderit, vero.</p>


      <h3>Lorem ipsum dolor sit.</h3><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus architecto
      consectetur corporis debitis deleniti dolorem eius harum inventore ipsa, natus necessitatibus praesentium quis
      ratione reiciendis, rem repudiandae ut vero. Velit.</p>
      <h3>Lorem ipsum dolor sit.</h3><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A architecto
      aspernatur at atque blanditiis, debitis dolores ex fugiat impedit incidunt, iste maiores nihil non obcaecati
      repudiandae sequi tempore veniam voluptates.</p>
      <h3>Lorem ipsum dolor sit.</h3><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, et fuga fugit id
      nostrum possimus quia quod temporibus unde. Accusantium deserunt dolore excepturi maiores officia quas quo
      reprehenderit temporibus veniam!</p>
      <h3>Lorem ipsum dolor.</h3><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aperiam assumenda
      consectetur, cupiditate dolor dolorem dolorum eligendi expedita fuga id mollitia nisi nulla odio possimus quia
      recusandae, similique. Amet debitis ducimus optio. Asperiores aut cum ducimus libero quisquam! At, atque?</p>
    </section>


    <Link to="/">Zurück zur Startseite</Link>
  </Layout>
);

export default SecondPage;
